import AbstractCustomerSupportService from './abstract';
import ENV from 'case-status/config/environment';
import $ from 'jquery';

/**
 * Implementation of the customer support service for legacy SaaS customer service providers.
 */
export default class LegacySaasCustomerSupportServiceImplementation extends AbstractCustomerSupportService {
	/**
	 * Sets the user information for the walkthrough widget.
	 * @param {Object} session - The session object.
	 * @param {Object} user - The user object.
	 * @param {Object} firmSettings - The firm settings object.
	 * @param {Object} activeChild - The active child user object.
	 */
	setPrimaryWidgetUser(session, user, firmSettings, activeChild) {
		const walkMeObj = {
			user: {
				userName: user.get('name'),
				userFirstName: user.get('firstName'),
				userLastName: user.get('lastName'),
				userId: user.get('id'),
				childId: session.get('data.authenticated.user_id'),
				firmId: user.get('firm.id'),
				firmName: user.get('firm.name'),
				firmIntegration: user.get('firm.integrationPartner'),
				firm: user.get('firm')
					? user.get('firm').toJSON()
					: user.get('organization')
					? user.get('organization').toJSON()
					: null,
				firmSettings: firmSettings?.firmSettings,
				userType: session.get('data.authenticated.user_type'),
				userEmail: user.get('emailAddress'),
				userCellPhone: user.get('cellPhone'),
				userDeviceType: user.get('deviceType'),
				userJobTitle: user.get('descriptor'),
				userLastOpened: user.get('lastOpened'),
				userPermissionType: activeChild?.permissionsType,
			},
		};

		window.walkMeUserVar = walkMeObj;
	}

	/**
	 * Dangerously renders the walkthrough widget by injecting the script into the head of the DOM.
	 * @returns {void}
	 */
	renderPrimaryWidget() {
		const scriptSrc = `https://cdn.walkme.com/users/29d6896bf96148c1ae11a4e0f0028d9a/${
			ENV.environment === 'production' ? '' : 'test/'
		}walkme_29d6896bf96148c1ae11a4e0f0028d9a_https.js`;

		// eslint-disable-next-line ember/no-jquery
		if (!$(`script[src="${scriptSrc}"]`).length) {
			const scriptTag = `<script type="text/javascript">
				(function() {
					console.log('Loading WalkMe...');
					var walkme = document.createElement('script');
					walkme.type = 'text/javascript';
					walkme.async = true;
					walkme.src = '${scriptSrc}';
					var s = document.getElementsByTagName('script')[0];
					s.parentNode.insertBefore(walkme, s);
					window._walkmeConfig = {smartLoad:true};
				})();
			</script>`;

			// eslint-disable-next-line ember/no-jquery
			$('head').append(scriptTag);
		}
	}

	/**
	 * Returns a reference to the secondary widget component to be used in tandem with component template helper.
	 * @returns {string|null} - The secondary widget component reference.
	 */
	getSecondaryWidgetComponent() {
		return 'zen-desk-script';
	}

	/**
	 * Returns the customer support email address.
	 * @returns {string} - The customer support email address.
	 */
	customerSupportEmail() {
		return 'support@casestatussupport.zendesk.com'
	}

	/**
	 * Returns the customer support external product link.
	 * @returns {string} - The customer support link.
	 */
	customerSupportLink() {
		return 'https://casestatussupport.zendesk.com/hc/en-us'
	}
}
