import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='ai-summary-node'>\n\t<span class='ai-summary-node-category'>{{this.args.category}}</span>\n\t<div class='ai-summary-node-content'>\n\t\t{{#if this.isContentString}}\n\t\t\t{{safe-html this.args.content}}\n\t\t{{else}}\n\t\t\t<ul>\n\t\t\t\t{{#each-in this.args.content as |key value|}}\n\t\t\t\t\t<li>{{safe-html value}}</li>\n\t\t\t\t{{/each-in}}\n\t\t\t</ul>\n\t\t{{/if}}\n\t</div>\n</div>", {"contents":"<div class='ai-summary-node'>\n\t<span class='ai-summary-node-category'>{{this.args.category}}</span>\n\t<div class='ai-summary-node-content'>\n\t\t{{#if this.isContentString}}\n\t\t\t{{safe-html this.args.content}}\n\t\t{{else}}\n\t\t\t<ul>\n\t\t\t\t{{#each-in this.args.content as |key value|}}\n\t\t\t\t\t<li>{{safe-html value}}</li>\n\t\t\t\t{{/each-in}}\n\t\t\t</ul>\n\t\t{{/if}}\n\t</div>\n</div>","moduleName":"case-status/components/ai-case-summary-node.hbs","parseOptions":{"srcName":"case-status/components/ai-case-summary-node.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AiCaseSummaryNodeComponent extends Component {
	constructor() {
		super(...arguments);
	}

	get isContentString() {
		return typeof this.args?.content === 'string';
	}
}
