/** @format */
import classic from 'ember-classic-decorator';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';
import ENV from 'case-status/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

@classic
export default class AccountMenu extends Component {
	@service session;
	@service currentUser;
	@storageFor('preferences') preferences;
	@tracked showAccountSwitcher = false;
	@service company;
	@service thirdParty;
	@service permissions;
	@service mobile;
	@service('saas-customer-support') saasCustomerSupport;

	get showSettings() {
		const userPermissions = this.permissions.get('user');

		return (
			userPermissions.settings_user_account ||
			userPermissions.settings_practice_areas ||
			userPermissions.settings_integrations ||
			userPermissions.settings_message_templates ||
			userPermissions.settings_firm ||
			userPermissions.settings_user_management
		);
	}

	host = ENV.host;

	@action
	setWantsMobile() {
		this.mobile.setWantsMobile();
	}

	@action
	closeAction() {
		if (this.close && typeof this.close === 'function') {
			this.close();
		}
	}

	@action
	logout() {
		this.currentUser.logout();
	}

	@action
	selectAccount(childId, childType) {
		this.set('session.data.authenticated.user_type', childType);
		this.set('session.data.authenticated.user_id', childId);
		this.set('preferences.selectedAccount', childId);
		this.set('preferences.selectedAccountType', childType);

		switch (childType) {
			case 'client':
				window.location.replace(`${window.location.origin}/app/client/cases`);
				break;
			case 'member':
				window.location.replace(
					`${window.location.origin}/app/organization/cases`,
				);
				break;
			case 'attorney':
				window.location.replace(`${window.location.origin}/app/firm/cases`);
				break;
			case 'paralegal':
				window.location.replace(`${window.location.origin}/app/firm/cases`);
				break;
			default:
				window.location.replace(`${window.location.origin}/app/`);
				break;
		}
	}

	@action
	onHelpCenterClick() {
	  window.open(this.saasCustomerSupport.customerSupportLink());
	}
}
