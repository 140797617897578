import Service from '@ember/service';
import Client from '../feature_gates/client';
import ENV from 'case-status/config/environment';

/**
 * Service for managing feature gates using FeatureGates Client.
 */
export default class FeatureGatesService extends Service {
	client = Client;

	/**
	 * Initializes the FeatureGateClient with the environment configuration.
	 * @returns {Promise<void>} A promise that resolves when the client is initialized.
	 */
	async initialize() {
		await this.client.init(ENV.statsigClientSecret, {
			environment: {
				tier: this.client.normalizeEnvironment(ENV.environment),
			},
		});
	}

	/**
	 * Checks if the client is initialized. Useful given feature gates may be used while the application is initializing
   * and currentUser may not be set.
	 *
	 * @returns {boolean} True if the client is initialized, false otherwise.
	 */
	get isInitialized() {
		return this.client.isInitialized;
	}

	/**
	 * Sets the user for the FeatureGateClient instance.
	 * @param {Object} user - The user object.
	 * @param {string} user.emailAddress - The user's email address.
	 * @param {string} user.firm - Ember data reference to the firm.
	 * @param {string} user.name - The user's name.
	 * @param {string} user.id - The user's ID.
	 * @param {string} user.type - The user's type.
	 */
	setUser(user) {
		this.client.withUser(user);
	}

	/**
	 * Evaluates the specified feature gate.
	 *
	 * @param {string} gateName - The name of the feature gate to evaluate.
	 * @param {boolean} [fallback=false] - The fallback value to return if the feature gate evaluation fails or is not available.
	 * @returns {boolean} - The result of the feature gate evaluation.
	 */
	eval(gateName, fallback = false) {
		return this.client.eval(gateName, fallback);
	}

	/**
	 * Retrieves the value of the specified experiment.
	 *
	 * @param {string} name - The name of the experiment to retrieve.
	 * @returns {Record<string, unknown>} - The value of the experiment.
	 */
	getExperiment(name) {
		return this.client.getExperiment(name);
	}

	/**
	 * Logs an event to A/B testing provider with the specified name, value, and metadata.
	 *
	 * @param {string} eventName - The name of the event to log.
	 * @param {*} [value=null] - The value associated with the event.
	 * @param {Object} [metadata=null] - Additional metadata to include with the event.
	 */
	logEvent(eventName, value = null, metadata = null) {
		this.client.logEvent(eventName, value, metadata);
	}
}
