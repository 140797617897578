import AbstractCustomerSupportService from './abstract';
import ENV from 'case-status/config/environment';
import Intercom, { update } from "@intercom/messenger-js-sdk";

export default class IntercomCustomerSupportServiceImplementation extends AbstractCustomerSupportService {
		/**
		 * Sets the user information for the intercom integration.
		 * @param {Object} session - The session object, which is unused in this implementation.
		 * @param {Object} user - The user object.
		 * @param {Object} firmSettings - The firm settings object.
		 * @param {Object} activeChild - The active child user object.
		 */
		setPrimaryWidgetUser(session, user, firmSettings, activeChild) {
			const firmId = user.get('firm.id') ?? user.get('organization.id');
			const firm = user.get('firm')
				? user.get('firm').toJSON()
				: user.get('organization')
				? user.get('organization').toJSON()
				: null;
			
			const intercomUser = {
					email: user.get('emailAddress'),
					user_id: user.get('id'),
					name: user.get('name'),
					phone: user.get('cellPhone'),
					company: firm ? {
						id: firmId,
						name: firm.name,
					} : {},
					'Child Id': session.get('data.authenticated.user_id').toString(),
					'Firm Integration': user.get('firm.integrationPartner'),
					'Device Type': user.get('deviceType'),
					'Job Title': user.get('descriptor'),
					'Permission Type': activeChild?.permissionsType,
					'User Type': user.get('type'),
					'User ID': user.get('id'),
			};
			update(intercomUser);
		}
	
		/**
		 * Renders the primary widget - Intercom.
		 * @returns {null} - null.
		 */
		renderPrimaryWidget() {
			Intercom(
				{
				app_id: ENV.intercomAppId,
				}
			)
			return null
		}
	
		/**
		 * There is a single widget rendered for this implementation. This method is unused.
		 * @returns {null} - Null.
		 */
		getSecondaryWidgetComponent() {		
			return null;
		}
	
		/**
		 * Returns the customer support email address.
		 * @returns {string} - The customer support email address.
		 */
		customerSupportEmail() {
			return 'intercom-support@casestatus.com'
		}
	
		/**
		 * Returns the customer support external product link.
		 * @returns {string} - The customer support link.
		 */
		customerSupportLink() {
			return 'https://intercom.help/case-status-support/en/'
		}
}
