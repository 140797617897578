import ApplicationAdapter from './application';
import { inject as service } from '@ember/service';
import { GATES, LAUNCH_NEW_RECOMMENDED_RESPONSES_MODEL } from 'case-status/feature_gates/constants';

export default ApplicationAdapter.extend({
	featureGateClient: service('feature-gates'),

	urlForQuery: function (query) {
		const launchNewRecommendedResponsesModel = this.get('featureGateClient').eval(GATES[LAUNCH_NEW_RECOMMENDED_RESPONSES_MODEL])
		if (query.messageId) {
			return `${this.host}/recommended_responses/${query.messageId}?${launchNewRecommendedResponsesModel ? 'by_batch=1' : ''}`;
		}
	},
});
