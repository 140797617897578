export default class AbstractCustomerSupportService {
	/**
	 * Sets the user information for the primary widget.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	setPrimaryWidgetUser() {
		throw new Error('Method setPrimaryWidgetUser() must be implemented.');
	}

	/**
	 * Renders the primary support widget.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	renderPrimaryWidget() {
		throw new Error('Method renderPrimaryWidget() must be implemented.');
	}

	/**
	 * Returns a reference to the secondary widget component.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	getSecondaryWidgetComponent() {
		throw new Error(
			'Method getSecondaryWidgetComponent() must be implemented.',
		);
	}

	/**
	 * Returns the customer support email address.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	customerSupportEmail() {
		throw new Error('Method customerSupportEmail() must be implemented.');
	}

	/**
	 * Returns the customer support link.
	 * This method must be implemented by subclasses.
	 * @throws {Error} - If the method is not implemented.
	 */
	customerSupportLink() {
		throw new Error('Method customerSupportLink() must be implemented.');
	}
}
