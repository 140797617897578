/**
 * Created by kevin on 6/15/17.
 *
 * @format
 */

import ObjectProxy from '@ember/object/proxy';

import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import RSVP from 'rsvp';
import Ember from 'ember';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { storageFor } from 'ember-local-storage';
import { paralegalDisplay } from '../helpers/paralegal-display';
import { attorneyDisplay } from '../helpers/attorney-display';
import { DEFAULT_ADMIN_PERMISSIONS } from 'case-status/constants/default-user-permissions';

export default Service.extend({
	chromeExtension: service(),
	featureGateClient: service('feature-gates'),
	pubnub: service('pubnub-service'),
	preferences: storageFor('preferences'),
	session: service(),
	store: service(),
	thirdParty: service(),
	hasOnlyOneCase: computed('user.case.length', function () {
		const userType = this.get('user.constructor.modelName');
		const userCasesLength = this.get('user.case.length');

		return userType == 'client' && userCasesLength === 1;
	}),
	usersChildren: A([]),
	userType: null,
	isLoaded: false,
	isPortalCustomer: false,
	saasCustomerSupport: service('saas-customer-support'),

	async load() {
		if (Ember.testing) {
			const user = ObjectProxy.create({
				id: 1,
				firstName: 'Phoenix',
				lastName: 'Wright',
				name: 'Phoenix Wright',
				emailAddress: 'p.wright@gmail.com',
				bio: "I'm an attorney",
				phoneNumber: '+18438675309',
				permissions: DEFAULT_ADMIN_PERMISSIONS,
			});
			this.set('user', user);
			this.set('isLoaded', true);
			featureGateClient.setUser(user);
			await featureGateClient.initialize();
			return RSVP.resolve();
		}

		const permissions = {
			automation: false,
			case_types: false,
			integration: false,
			invite_users: false,
			partner_portal: false,
			treatment_log: false,
			e_sign: false,
		};

		this.set('permissions', permissions);

		const childId = this.get('session.data.authenticated.user_id');
		const userType = this.get('session.data.authenticated.user_type');

		if (!isEmpty(childId)) {
			return this.store
				.findRecord(userType, childId)
				.then((user) => {
					return this.store
						.query('usersChild', { userId: user.id })
						.then(async (children) => {
							this.set(
								'usersChildren',
								children.map((child) => {
									if (children.length === 1 || child.childId == user.id) {
										child.active = true;
										// Edge case where we are defaulting to the wrong user child id
										this.set(
											'session.data.authenticated.user_id',
											child.childId,
										);
										this.set(
											'session.data.authenticated.user_type',
											child.type,
										);
										// If the child is active then set the child permissions on the user
										user.set('permissions', child.userPermissions);
									} else {
										child.active = false;
									}

									if (child.type === 'paralegal') {
										child.set('title', paralegalDisplay([child.firm, 1, true]));
									} else if (child.type === 'attorney') {
										child.set('title', attorneyDisplay([child.firm]));
									} else {
										child.set('title', child.get('type'));
									}
									return child;
								}),
							);

							this.set('userType', userType);

							if (userType === 'paralegal') {
								this.set('title', paralegalDisplay([user.firm, 1, true]));
							} else if (userType === 'attorney') {
								this.set('title', attorneyDisplay([user.firm]));
							} else {
								this.set('title', userType);
							}

							this.set('user', user);

							this.featureGateClient.setUser(user);
							await this.featureGateClient.initialize();

							this.set('isLoaded', true);

							const firmSettings = await user.get('firm.firmSettings');

							if (
								(userType === 'paralegal' || userType === 'attorney') &&
								firmSettings.firmSettings?.client_portal_texting
							) {
								this.set('isPortalCustomer', true);
							}

							const activeChild = children.find((child) => child.active);

							if (!this.saasCustomerSupport.isLegacy) this.saasCustomerSupport.renderPrimaryWidget();
							
							this.saasCustomerSupport.setPrimaryWidgetUser(this.session, user, firmSettings, activeChild);

							const firmOrg = user.organization ? 'organization' : 'firm';

							//* load custom branding, set branding on user
							user.get(`${firmOrg}.customBranding`).then((branding) => {
								user.set('branding', branding);
							});

							switch (userType) {
								case 'member':
									//* Subscribe to PubNuB notifications
									this.pubnub.subscribe(childId, userType, null);
									this.set(
										'orgRegion',
										user.get('organization.phoneNumberRegion'),
									);
									//* return setup user
									return user;
								default: //* userTypes: [attorney, paralegal, client]
									//* Subscribe to PubNuB notifications
									this.pubnub.subscribe(childId, userType, user.get('firm.id'));

									//* Set the firmRegion (phoneNumberRegion on firm)
									this.set('firmRegion', user.get('firm.phoneNumberRegion'));
									//*
									this.set('isCorporate', user.get('firm.isCorporate'));

									return user;
							}
						});
				})
				.catch((err) => {
					// This might have failed because the user has an invalid (unlinked) child
					// If the user has an invalid child we will want to clear that info from storage
					// before they are kicked out of the app so they can attempt to login again
					this.preferences.clear();
					console.error(err);
				});
		} else {
			return RSVP.resolve();
		}
	},

	logout() {
		this.pubnub.unsubscribe();
		this.preferences.clear();
		this.session.invalidate();
		this.chromeExtension.sendLoggedOut();
	},
});
